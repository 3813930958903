import React, { useEffect } from 'react';

import './App.css';
import 'react-toastify/dist/ReactToastify.css';

import { Portal } from '@material-ui/core';
import { GoogleLoginResponse } from 'react-google-login';
import { Redirect, Route, Switch } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';

import Sidebar from './components/Sidebar/Sidebar';
import { useLoginContext } from './contexts/loginContext';
import Auth, { Permission } from './libraries/auth';
import HomePage from './pages/home';
import LoginPage from './pages/login';
import { StorageKey } from './storage/storageKey';
import { storageManager } from './storage/storageManager';

const routes: { path: string; component: React.FC; permission?: Permission }[] = [
  { path: '/home', component: HomePage, permission: Permission.NEED_LOGIN },
  { path: '/login', component: LoginPage, permission: Permission.NEED_NO_LOGIN },
];

const App: React.FC = () => {
  const { setLoggedEmail } = useLoginContext();

  useEffect(() => {
    // TODO 보안이 너무 취약함: localStorage 는 조작 가능하다.
    const authInfo = storageManager.get(StorageKey.authorization) as GoogleLoginResponse | null;
    if (!authInfo) return;
    setLoggedEmail(authInfo.profileObj.email);
  }, []);

  return (
    <>
      <Switch>
        {routes.map((item, i) => (
          <Route key={i} path={item.path} component={Auth(item.component, item.permission)} exact />
        ))}
        <Redirect to={'/login'} />
      </Switch>
      <ToastContainer
        position="top-center"
        autoClose={2000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <Portal>
        <Sidebar />
      </Portal>
    </>
  );
};

export default App;
