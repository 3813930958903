import React, { createContext, useContext, useMemo, useState } from 'react';

export interface ILoginContext {
  loggedEmail: string | null;
  setLoggedEmail: (logged: string | null) => void;
}

const loginContext = createContext<ILoginContext>({ loggedEmail: null, setLoggedEmail: () => null });

export const LoginProvider: React.FC = ({ children }) => {
  const [loggedEmail, setLoggedEmail] = useState<string | null>(null);

  const value = useMemo(
    () => ({
      loggedEmail,
      setLoggedEmail,
    }),
    [loggedEmail, setLoggedEmail]
  );

  return <loginContext.Provider value={value}>{children}</loginContext.Provider>;
};

export function useLoginContext(): ILoginContext {
  return useContext(loginContext) as ILoginContext;
}
