import React, { useState } from 'react';

import { Button, Card, Typography } from '@material-ui/core';
import DehazeIcon from '@material-ui/icons/Dehaze';
import classNames from 'classnames';
import { GoogleLogout } from 'react-google-login';
import { toast } from 'react-toastify';

import { useLoginContext } from '../../contexts/loginContext';
import { GOOGLE_CLIENT_ID } from '../../libraries/auth';
import { StorageKey } from '../../storage/storageKey';
import { storageManager } from '../../storage/storageManager';

import styles from './Sidebar.module.scss';

interface Props {}

const Sidebar: React.FC<Props> = () => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const { loggedEmail, setLoggedEmail } = useLoginContext();

  const handleLogout = async () => {
    await setLoggedEmail(null);
    await storageManager.remove(StorageKey.authorization);
    toast.success('로그아웃 되었습니다.');
  };

  return (
    <div className={styles.wrapper}>
      <DehazeIcon className={styles.icon} onClick={() => setIsOpen(!isOpen)} />
      {loggedEmail ? (
        <Card className={classNames(styles.content, !isOpen && styles.hidden)}>
          <Typography variant={'caption'} color={'textPrimary'}>
            Logged in as
          </Typography>
          <Typography variant={'body1'} color={'textPrimary'}>
            {loggedEmail}
          </Typography>
          <br />
          <br />
          <GoogleLogout
            clientId={GOOGLE_CLIENT_ID}
            render={(renderProps) => (
              <Button
                onClick={async () => {
                  await handleLogout();
                  renderProps.onClick();
                }}
                disabled={renderProps.disabled}
                variant={'contained'}
                color={'primary'}
              >
                Logout
              </Button>
            )}
          />
        </Card>
      ) : (
        <Card className={classNames(styles.content, !isOpen && styles.hidden)}>이용하시려면 로그인해 주세요.</Card>
      )}
    </div>
  );
};

export default Sidebar;
