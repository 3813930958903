import { StorageKey } from './storageKey';

const keyToString = (key: StorageKey) => `${key}`;

export const storageManager = {
  get: (key: StorageKey): null | string | unknown => {
    const item = localStorage.getItem(keyToString(key));
    return item && JSON.parse(item);
  },
  add: (key: StorageKey, item: unknown): void => {
    const currentItem = localStorage.getItem(keyToString(key));
    if (currentItem) throw new Error('item already exists: use set instead');

    localStorage.setItem(keyToString(key), JSON.stringify(item));
  },
  set: (key: StorageKey, item: unknown): void => {
    localStorage.setItem(keyToString(key), JSON.stringify(item));
  },
  remove: (key: StorageKey): void => {
    const currentItem = localStorage.getItem(keyToString(key));
    if (!currentItem) throw new Error('cannot remove: storage[key] is null');

    localStorage.removeItem(keyToString(key));
  },
};
