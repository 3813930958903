import React from 'react';

import ReactDOM from 'react-dom';
import './index.css';
import { BrowserRouter } from 'react-router-dom';

import App from './App';
import { LoginProvider } from './contexts/loginContext';

ReactDOM.render(
  <LoginProvider>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </LoginProvider>,
  document.getElementById('root')
);
