import React, { useState } from 'react';

import { Button, Checkbox, FormControlLabel } from '@material-ui/core';
import GoogleLogin, { GoogleLoginResponse, GoogleLoginResponseOffline } from 'react-google-login';
import { toast } from 'react-toastify';

import { useLoginContext } from '../../contexts/loginContext';
import { EMAIL_WHITELIST, GOOGLE_CLIENT_ID } from '../../libraries/auth';
import { StorageKey } from '../../storage/storageKey';
import { storageManager } from '../../storage/storageManager';

const LoginPage: React.FC = () => {
  const { setLoggedEmail } = useLoginContext();
  const [isAutoLoginChecked, setAutoLoginChecked] = useState<boolean>(false);

  const responseGoogle = (e: GoogleLoginResponse | GoogleLoginResponseOffline) => {
    if ('profileObj' in e) {
      const email = e.profileObj.email;
      if (EMAIL_WHITELIST.includes(email)) {
        toast.success('로그인 성공하였습니다.');
        isAutoLoginChecked && storageManager.add(StorageKey.authorization, e);
        setLoggedEmail(email);
      } else {
        toast.error('등록되지 않은 계정입니다.');
      }
    }
  };

  return (
    <div
      style={{
        position: 'fixed',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
      }}
    >
      <GoogleLogin
        clientId={GOOGLE_CLIENT_ID}
        render={(renderProps) => (
          <Button onClick={renderProps.onClick} disabled={renderProps.disabled} variant={'contained'} color={'primary'}>
            Login
          </Button>
        )}
        buttonText="Login"
        onSuccess={responseGoogle}
        onFailure={responseGoogle}
        cookiePolicy={'single_host_origin'}
      />
      <FormControlLabel
        control={
          <Checkbox color={'primary'} checked={isAutoLoginChecked} onChange={() => setAutoLoginChecked(!isAutoLoginChecked)} />
        }
        label={'자동 로그인'}
      />
    </div>
  );
};

export default LoginPage;
