import React from 'react';

import { useHistory } from 'react-router-dom';

import { useLoginContext } from '../contexts/loginContext';

export const GOOGLE_CLIENT_ID = '240122992978-lb7pgj20kk6v1ba848rksskoavtjs0gb.apps.googleusercontent.com';

export const EMAIL_WHITELIST = ['woohm404@gmail.com', 'hursuhyun@gmail.com'];

export enum Permission {
  NEED_LOGIN = '로그인 되어있어야 함',
  NEED_NO_LOGIN = '로그인 안 되어있어야 함',
}

const Auth = (component: React.FC, permission?: Permission): React.ComponentType<unknown> | undefined => {
  const { loggedEmail } = useLoginContext();
  const isLogged = loggedEmail !== null;
  const history = useHistory();

  if (!permission) return component;

  if (permission === Permission.NEED_NO_LOGIN && isLogged) {
    history.push('/home');
    return undefined;
  }

  if (permission === Permission.NEED_LOGIN && !isLogged) {
    history.push('/login');
    return undefined;
  }

  return component;
};

export default Auth;
